<template>
    <div>
        <h1 class="text-center display-3 py-md-5">Dokumenttien hallinta</h1>

        <v-form ref="form" class="mb-10">
            <v-text-field
                v-model="form.description"
                prepend-icon="mdi-pencil"
                :rules="[requiredRule]"
                label="Dokumentin kuvaus"
            ></v-text-field>

            <v-file-input
                v-model="form.file"
                label="Dokumentti"
                :rules="[requiredRule]"
            ></v-file-input>

            <div class="text-right">
                <v-btn color="primary" @click="submit">Lähetä</v-btn>
            </div>
        </v-form>

        <FileList :files="docs" can-delete @delete="fileDeleted" />
    </div>
</template>

<script>
    import FileList from "@/components/FileList";
    export default {
        components: { FileList },
        data() {
            return {
                requiredRule: v => !!v || this.$t("common.requiredField"),
                docs: [],
                form: {
                    description: "",
                    file: null
                }
            };
        },

        methods: {
            submit() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                const fd = new FormData();
                fd.append("description", this.form.description);
                fd.append("file", this.form.file);
                this.$http.post("/api/documents", fd).then(response => {
                    this.docs.push(response.data);
                    this.form.description = "";
                    this.form.file = null;
                    this.$refs.form.resetValidation();
                });
            },

            fetchDocuments() {
                this.$http
                    .get("/api/documents")
                    .then(response => (this.docs = response.data));
            },

            fileDeleted(file) {
                this.docs = this.docs.filter(doc => doc.id !== file.id);
            }
        },

        created() {
            this.fetchDocuments();
        }
    };
</script>

<style scoped>
</style>