<template>
    <v-dialog v-bind="$attrs" v-on="$listeners" max-width="720">
        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Muokkaa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container>
                <UserForm
                    ref="form"
                    type="edit"
                    @submitted="formSubmitted"
                ></UserForm>
            </v-container>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                    text
                    color="error"
                    @click="deleteUser"
                    :disabled="!canDelete"
                >
                    <v-icon>mdi-delete</v-icon>
                    Poista
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog">Peruuta</v-btn>
                <v-btn text color="primary" @click="submit">Muokkaa</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import UserForm from "./UserForm";
    export default {
        inheritAttrs: false,
        components: { UserForm },
        data() {
            return {
                currentUser: null // This will just hold info when deleting user
            };
        },
        methods: {
            closeDialog() {
                this.$emit("input", false);
                this.resetForm();
            },

            resetForm() {
                this.$refs.form.resetValidation();
            },

            submit() {
                this.$refs.form.submit();
            },

            setUser(user) {
                // Forward to form itself
                this.$refs.form.setUser(user);
                this.currentUser = user;
            },

            formSubmitted(user) {
                this.closeDialog();
                this.currentUser = user;
                this.$emit("user-edited", user);
            },

            async deleteUser() {
                const del = await this.$dialog.confirm({
                    title: "Poista käyttäjä",
                    text: "Haluatko varmasti poistaa tämän käyttäjän?",
                    actions: {
                        false: "Peruuta",
                        true: {
                            text: "Poista",
                            color: "error"
                        }
                    }
                });

                if (!del) {
                    return;
                }

                this.$http
                    .delete(`/api/users/${this.currentUser.id}`)
                    .then(response => {
                        this.$emit("user-deleted", this.currentUser.id);
                        this.closeDialog();
                    })
                    .catch(err => {
                        this.$dialog.notify.error(err.response.data.message);
                    });
            }
        },

        computed: {
            ...mapState(["user"]),
            canDelete() {
                return this.currentUser && this.user.id != this.currentUser.id;
            }
        }
    };
</script>

<style scoped>
</style>