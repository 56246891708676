<template>
    <v-list two-line>
        <v-list-item v-for="doc in files" :key="doc.id">
            <v-list-item-avatar>
                <v-icon>mdi-file</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="doc.description"></v-list-item-title>
                <v-list-item-subtitle
                    v-text="doc.filename"
                ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-flex>
                    <v-btn
                        v-if="canDelete"
                        icon
                        @click="deleteFile(doc)"
                        color="error"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="downloadFile(doc.id)" color="primary">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-flex>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        props: {
            files: {
                required: true,
                type: Array
            },

            canDelete: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        methods: {
            downloadFile(id) {
                document.location.href = `/api/documents/${id}`;
            },

            async deleteFile(file) {
                const del = await this.$dialog.warning({
                    title: "Poista tiedosto",
                    text: "Haluatko varmasti poistaa tämän tiedoston?"
                });

                if (del) {
                    this.$http
                        .delete(`/api/documents/${file.id}`)
                        .then(response => {
                            this.$emit("delete", file);
                        });
                }
            }
        }
    };
</script>

<style scoped>
</style>