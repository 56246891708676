<template>
    <v-dialog v-bind="$attrs" v-on="$listeners" max-width="720">
        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Lisää käyttäjä</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container>
                <v-alert v-if="errorMessage" type="error">
                    {{ errorMessage }}
                </v-alert>

                <v-form ref="form" lazy-validation>
                    <v-text-field
                        v-model="user.username"
                        prepend-inner-icon="mdi-account"
                        :rules="[requiredRule]"
                        label="Käyttäjänimi"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.name"
                        prepend-inner-icon="mdi-account"
                        :rules="[requiredRule]"
                        label="Nimi"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.block"
                        prepend-inner-icon="mdi-factory"
                        label="Lohkotunniste"
                    >
                        <template #append>
                            <v-tooltip bottom max-width="320">
                                <template #activator="{ on, attrs }">
                                    <v-icon v-on="on" v-bind="attrs"
                                        >mdi-information</v-icon
                                    >
                                </template>
                                Käyttäjät lisäävät potilaita lohkoihin, joissa
                                tämä tieto toimii tunnisteena. Jos haluat
                                useamman käyttäjän jakavan saman lohkon, niin
                                anna niille sama tunniste.<br /><br />

                                Voit jättää tämän tyhjäksi jolloin käyttäjä ei
                                jaa lohkoa kenenkään kanssa.
                            </v-tooltip>
                        </template>
                    </v-text-field>

                    <v-text-field
                        v-model="user.password"
                        prepend-inner-icon="mdi-lock"
                        type="password"
                        :rules="[
                            requiredRule,
                            (v) =>
                                v.length >= 8 ||
                                'Kirjoita vähintään 8 merkin salasana',
                        ]"
                        label="Salasana"
                    ></v-text-field>

                    <v-textarea
                        v-model="user.notes"
                        label="Muistiinpanot"
                        hint="Esim. yhteyshenkilön tiedot, puhelinnumeroita jne..."
                        prepend-icon="mdi-pencil"
                    >
                    </v-textarea>

                    <v-checkbox
                        v-model="user.is_admin"
                        label="Admin-tunnus"
                    ></v-checkbox>
                </v-form>
            </v-container>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog">Peruuta</v-btn>
                <v-btn text color="primary" @click="submit">Lisää</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    inheritAttrs: false,
    data() {
        return {
            requiredRule: (v) => !!v || this.$t("common.requiredField"),
            errors: {},

            user: {
                name: "",
                username: "",
                block: "",
                notes: "",
                password: "",
                is_admin: 0,
            },
        };
    },
    methods: {
        closeDialog() {
            this.$emit("input", false);
            this.resetForm();
        },

        resetForm() {
            this.user = {
                name: "",
                username: "",
                notes: "",
                password: "",
                is_admin: 0,
            };

            this.$refs.form.resetValidation();
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.errors = {};
            this.$http
                .post("/api/users", this.user)
                .then((response) => {
                    this.$emit("user-added", response.data);
                    this.closeDialog();
                })
                .catch((err) => (this.errors = err.response.data.errors));
        },
    },

    computed: {
        errorMessage() {
            console.log(this.errors);
            const keys = Object.keys(this.errors);
            if (keys.length === 0) {
                return false;
            }

            return this.errors[keys[0]][0];
        },
    },
};
</script>

<style scoped></style>
