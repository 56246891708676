<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="92"></v-progress-circular>
        </v-overlay>

        <h1 class="text-center display-3 py-md-5">Käyttäjähallinta</h1>

        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-account-search"
                    label="Rajaa käyttäjiä"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:item.is_admin="{ item }">
                    {{ item.is_admin ? "Kyllä" : "Ei" }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn text @click="editUser(item)">Muokkaa</v-btn>
                </template>
            </v-data-table>

            <v-card-actions class="mt-4">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click.stop="createUserDialogVisible = true"
                >
                    Luo käyttäjä
                </v-btn>
            </v-card-actions>
        </v-card>

        <CreateUserDialog
            v-model="createUserDialogVisible"
            @user-added="userAdded"
        />

        <EditUserDialog
            ref="editDialog"
            v-model="editUserDialogVisible"
            @user-edited="userEdited"
            @user-deleted="userDeleted"
        />
    </div>
</template>

<script>
    import CreateUserDialog from "@/components/user-admin/CreateUserDialog";
    import EditUserDialog from "@/components/user-admin/EditUserDialog";

    export default {
        components: { CreateUserDialog, EditUserDialog },
        data: function() {
            return {
                headers: [
                    { value: "id", text: "ID", sortable: false },
                    { value: "username", text: "Käyttäjänimi" },
                    { value: "name", text: "Nimi" },
                    { value: "is_admin", text: "Admin", filterable: false },
                    { value: "actions", text: "", align: "right" }
                ],
                users: [],
                loading: false,
                search: "",

                createUserDialogVisible: false,
                editUserDialogVisible: false
            };
        },

        methods: {
            fetchUsers() {
                this.loading = true;
                this.$http.get("/api/users").then(response => {
                    this.users = response.data;
                    this.loading = false;
                });
            },

            editUser(user) {
                this.editUserDialogVisible = true;
                this.$nextTick(() => this.$refs.editDialog.setUser(user));
            },

            userAdded(user) {
                this.users.push(user);
            },

            userEdited(user) {
                const i = this.users.findIndex(u => u.id === user.id);
                if (i) {
                    this.users.splice(i, 1, user);
                }
            },

            userDeleted(id) {
                const i = this.users.findIndex(u => u.id === id);
                if (i) {
                    this.users.splice(i, 1);
                }
            }
        },

        created() {
            this.fetchUsers();
        }
    };
</script>

<style lang="css" scoped>
</style>