<template>
    <v-form ref="form" lazy-validation>
        <v-container>
            <v-alert v-if="errorMessage" type="error">
                {{ errorMessage }}
            </v-alert>
        </v-container>

        <v-text-field
            v-model="user.username"
            prepend-inner-icon="mdi-account"
            :rules="[requiredRule]"
            label="Käyttäjänimi"
        ></v-text-field>

        <v-text-field
            v-model="user.name"
            prepend-inner-icon="mdi-account"
            :rules="[requiredRule]"
            label="Nimi"
        ></v-text-field>

        <v-text-field
            v-model="user.block"
            prepend-inner-icon="mdi-factory"
            label="Lohkotunniste"
        >
            <template #append>
                <v-tooltip bottom max-width="320">
                    <template #activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs"
                            >mdi-information</v-icon
                        >
                    </template>
                    Käyttäjät lisäävät potilaita lohkoihin, joissa tämä tieto
                    toimii tunnisteena. Jos haluat useamman käyttäjän jakavan
                    saman lohkon, niin anna niille sama tunniste.<br /><br />

                    Voit jättää tämän tyhjäksi jolloin käyttäjä ei jaa lohkoa
                    kenenkään kanssa.
                </v-tooltip>
            </template>
        </v-text-field>

        <v-text-field
            v-model="user.password"
            prepend-inner-icon="mdi-lock"
            type="password"
            :rules="passwordRules"
            label="Salasana"
        ></v-text-field>

        <v-textarea
            v-model="user.notes"
            label="Muistiinpanot"
            hint="Esim. yhteyshenkilön tiedot, puhelinnumeroita jne..."
            prepend-inner-icon="mdi-pencil"
        >
        </v-textarea>

        <v-checkbox v-model="user.is_admin" label="Admin-tunnus"></v-checkbox>
    </v-form>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: "create",
        },
    },

    data() {
        return {
            requiredRule: (v) => !!v || this.$t("common.requiredField"),
            errors: {},

            user: {
                name: "",
                block: "",
                username: "",
                notes: "",
                password: "",
                is_admin: 0,
            },
        };
    },

    methods: {
        setUser(user) {
            this.user = { ...user };
        },

        resetValidation() {
            this.$refs.form.resetValidation();
            this.errors = {};
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.errors = {};
            const ajaxValues = this.getAjaxOptions();
            this.$http(ajaxValues)
                .then((response) => {
                    this.$emit("submitted", response.data);
                })
                .catch((err) => {
                    this.errors =
                        err.response.data.errors || err.response.data.message;
                });
        },

        getAjaxOptions() {
            return this.type === "create"
                ? {
                      method: "post",
                      url: "/api/users",
                      data: this.user,
                  }
                : {
                      method: "put",
                      url: `/api/users/${this.user.id}`,
                      data: this.user,
                  };
        },
    },

    computed: {
        errorMessage() {
            if (typeof this.errors === "string") {
                return this.errors;
            }

            const keys = Object.keys(this.errors);
            if (keys.length === 0) {
                return false;
            }

            return this.errors[keys[0]][0];
        },

        passwordRules() {
            if (this.type === "create") {
                return [
                    this.requiredRule,
                    (v) =>
                        v.length >= 8 || "Kirjoita vähintään 8 merkin salasana",
                ];
            } else {
                return [];
            }
        },
    },
};
</script>

<style scoped></style>
